import { FieldErrors, FieldValues } from 'react-hook-form'

import { darkGrey, errorRed, mediumGrey } from '@ui/theme/colors'

const getNestedValue = (obj: any, path: string) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

export const getFieldError = (errors: FieldErrors<FieldValues>, name: string) => {
  const error = getNestedValue(errors, name)
  const hasError = !!error
  const errorMessage = error?.message?.toString()
  return { hasError, errorMessage }
}

export const getColorForState = ({ error, disabled }: { error: boolean; disabled: boolean }) =>
  disabled ? mediumGrey : error ? errorRed : darkGrey
