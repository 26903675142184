import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { FieldValues, UseFormSetError } from 'react-hook-form'

import { ViewerGlobalFragmentDoc } from '@graph/fragments/ViewerGlobal.generated'
import {
  updateViewerCache,
  extractFirstErrorFromMutation,
  getSetErrorArgsFromMutationError,
  getSetErrorArgsFromGraphQlError,
} from '@graph/utils'
import { LoginUserEmailDocument } from '@graph/mutations/LoginUserEmail.generated'
import { clearOnboardingLocalStorage } from 'onboarding/utils'
import { captureSentryException } from 'app/utils'

type UseEmailLoginUserTuple = [(email: string, password: string) => void, { loading: boolean }]

const useEmailLoginUser = <T extends FieldValues>({
  onSuccess,
  setError,
}: {
  onSuccess: () => void
  setError: UseFormSetError<T>
}): UseEmailLoginUserTuple => {
  const [mutation, { loading }] = useMutation(LoginUserEmailDocument, {
    update(cache, { data }) {
      updateViewerCache(
        cache,
        data?.emailLoginUser?.viewer?.user,
        ViewerGlobalFragmentDoc,
        'ViewerGlobal'
      )
    },
  })

  const emailLoginUser = useCallback(
    async (email: string, password: string) => {
      type SetErrorName = Parameters<typeof setError>[0]

      try {
        const response = await mutation({
          variables: {
            input: { email, password },
          },
        })

        const error = extractFirstErrorFromMutation(response)

        if (error) {
          const setErrorArgs = getSetErrorArgsFromMutationError<SetErrorName>(error, {
            email: 'email',
            password: 'password',
          })

          return setError(...setErrorArgs)
        }

        clearOnboardingLocalStorage(true)
        onSuccess()
      } catch (error) {
        captureSentryException(error)
        const setErrorArgs = getSetErrorArgsFromGraphQlError<SetErrorName>(error)

        setError(...setErrorArgs)
      }
    },
    [mutation, onSuccess, setError]
  )

  return [emailLoginUser, { loading }]
}

export default useEmailLoginUser
