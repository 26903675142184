import { useFormContext } from 'react-hook-form'
import { InputHTMLAttributes, ReactElement } from 'react'

import Stack from '@ui/layout/stack'

import { ErrorMessage } from '../ErrorMessage'
import { FormLabel } from '../FormLabel'
import { getFieldError } from '../utils'
import TextInput, { InputTypes } from './TextInput'

type TextFieldProps = {
  name: string
  label?: string | React.ReactNode
  type?: InputTypes
  underline?: boolean
  icon?: ReactElement
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
} & InputHTMLAttributes<HTMLInputElement>

const TextField = ({
  name,
  label,
  type = InputTypes.TEXT,
  disabled = false,
  underline,
  icon,
  onChange,
  ...rest
}: TextFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const result = getFieldError(errors, name)
  const hasError = result.hasError
  const errorMessage = result.errorMessage

  const getLabel = () => (typeof label === 'string' ? <FormLabel>{label}</FormLabel> : label)

  return (
    <Stack gap='1'>
      {label && getLabel()}
      <TextInput
        hasError={hasError}
        type={type}
        disabled={disabled}
        underline={underline}
        icon={icon}
        {...register(name, { onChange })}
        {...rest}
      />
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </Stack>
  )
}

export default TextField
