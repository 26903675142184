import { useState, useEffect, useCallback } from 'react'

const GOOGLE_GSI_CLIENT_URI = 'https://accounts.google.com/gsi/client'
const NUM_DEFAULT_LOAD_RETRIES = 3

export default function useGoogleSignInSdk() {
  const [sdk, setSdk] = useState<typeof google | null>(null)
  const [isLoading, setLoading] = useState(false)

  const loadScript = (src: string, callback: (error: any) => void) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => callback(null)
    script.onerror = (error: any) => callback(error)
    document.head.appendChild(script)
  }

  const loadGoogleSignInSdk = useCallback((retries = NUM_DEFAULT_LOAD_RETRIES) => {
    setLoading(true)

    loadScript(GOOGLE_GSI_CLIENT_URI, (error) => {
      setLoading(false)

      if (!error) {
        setSdk(window.google)
      } else if (error && retries > 0) {
        loadGoogleSignInSdk(retries - 1)
      }
    })
  }, [])

  useEffect(() => {
    if (sdk || isLoading) return

    loadGoogleSignInSdk()
  }, [sdk, isLoading, loadGoogleSignInSdk])

  return {
    sdk,
  }
}
