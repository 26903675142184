import { useRouter } from 'next/router'

import { dashboardPath, loginPath } from 'routes/static'
import ButtonLink from '@ui/button/ButtonLink'
import Stack from '@ui/layout/stack'
import { buildOnboardingPath } from 'routes/onboarding'

const NavButtons = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const router = useRouter()
  return (
    <Stack direction='horizontal' gap='4' valign='center' align='right'>
      {isLoggedIn ? (
        <ButtonLink
          minWidth={{ _: '85px', desktop: '132px' }}
          variant='transparent'
          href={dashboardPath}
        >
          Dashboard
        </ButtonLink>
      ) : (
        <>
          <ButtonLink
            minWidth={{ _: '85px', desktop: '132px' }}
            variant='flat'
            href={loginPath}
            eventClickTarget='external-top-nav-join'
          >
            Login
          </ButtonLink>

          <ButtonLink
            minWidth={{ _: '85px', desktop: '132px' }}
            variant='transparent'
            href={buildOnboardingPath({ back: router.asPath })}
            eventClickTarget='external-top-nav-login'
          >
            Join
          </ButtonLink>
        </>
      )}
    </Stack>
  )
}

export default NavButtons
