import Link from 'next/link'

import { ContentBlock } from '@ui/layout/ContentBlock'
import Stack from '@ui/layout/stack'
import { homePath } from 'routes/static'
import { useViewerGlobalContext } from 'viewer/ViewerGlobalContext'

import Logo from '../Logo'
import NavButtons from './NavButtons'

const PrimaryNavigationPublic = () => {
  const viewer = useViewerGlobalContext()

  return (
    <ContentBlock maxWidth='page.content.maxWidth' p='4'>
      <ContentBlock>
        <Stack as='nav' direction='horizontal' align='space-between' valign='center'>
          <Link prefetch={false} href={homePath}>
            <Logo />
          </Link>
          <NavButtons isLoggedIn={viewer.isLoggedIn} />
        </Stack>
      </ContentBlock>
    </ContentBlock>
  )
}

export default PrimaryNavigationPublic
